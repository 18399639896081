function Loader(options) {
  if (!(this instanceof Loader)) return new Loader(options);

  this.$loader = document.querySelector('.loader');
  this.$barStatus = document.querySelector('.loader__bar-status');
  this.$words = document.querySelectorAll('.word');
  this.wordArray = [];
  this.currentWord = 0;
  this.isWithText = false;
  this.isSessionInit = (typeof sessionStorage.getItem('is-loader-init') === 'string' && sessionStorage.getItem('is-loader-init') === 'true') ? true : false;

  if (this.$loader && !this.isSessionInit) {
    this.init();
  }

  return this;
};

Loader.prototype.init = function() {
  sessionStorage.setItem('is-loader-init', 'true');

  var dataIsWithText = this.$loader.getAttribute('data-is-with-text');
  this.isWithText = (dataIsWithText === 'true') ? true : false;

  this.$loader.className += ' is-init';

  this.$words[this.currentWord].style.opacity = 1;
  for (var i = 0; i < this.$words.length; i++) {
    this.splitLetters(this.$words[i]);
  }

  this.$barStatus.style.width = '50%';

  this.storeLoop = setInterval(function() {

    var store = ((window.APP || {}).store || {});

    if (Object.keys(store).length) {

      this.finish();
      clearInterval(this.storeLoop);
    }
  }.bind(this), 100);
};

Loader.prototype.finish = function() {
  this.$barStatus.style.width = '100%';

  if (this.isWithText) {
    setTimeout(function() {
      this.$loader.className += ' is-load';
    }.bind(this), 500);

    setTimeout(function() {
      this.changeWord();
    }.bind(this), 3000);

    setTimeout(function() {
      this.close();
    }.bind(this), 6000);
  } else {
    setTimeout(function() {
      this.close();
    }.bind(this), 500);
  }
};

Loader.prototype.close = function() {
  this.$loader.className += ' is-close';

  setTimeout(function() {
    this.$barStatus.style.width = '0';
    this.$loader.className = this.$loader.className.replace(new RegExp('(^|\\b)' + 'is-init is-load'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }.bind(this), 1000);
};

Loader.prototype.reInit = function() {
  this.$loader.className = this.$loader.className.replace(new RegExp('(^|\\b)' + 'is-close'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
};

Loader.prototype.changeWord = function() {
  var cw = this.wordArray[this.currentWord];
  var nw = this.currentWord == this.$words.length-1 ? this.wordArray[0] : this.wordArray[this.currentWord+1];
  for (var i = 0; i < cw.length; i++) {
    this.animateLetterOut(cw, i);
  }

  for (var i = 0; i < nw.length; i++) {
    nw[i].className = 'letter behind';
    nw[0].parentElement.style.opacity = 1;
    this.animateLetterIn(nw, i);
  }

  this.currentWord = (this.currentWord == this.wordArray.length-1) ? 0 : this.currentWord+1;
};

Loader.prototype.animateLetterOut = function(cw, i) {
  setTimeout(function() {
    cw[i].className = 'letter out';
  }, i*80);
};

Loader.prototype.animateLetterIn = function(nw, i) {
  setTimeout(function() {
    nw[i].className = 'letter in';
  }, 340+(i*80));
};

Loader.prototype.splitLetters = function(word) {
  var content = word.innerHTML;
  word.innerHTML = '';
  var letters = [];

  for (var i = 0; i < content.length; i++) {
    var letter = document.createElement('span');
    letter.className = 'letter';
    letter.innerHTML = content.charAt(i);
    word.appendChild(letter);
    letters.push(letter);
  }

  this.wordArray.push(letters);
};

var loader = new Loader();

// window.onbeforeunload = function(event) {
//
//   var $link = ((event.target || {}).activeElement || {});
//
//   if (typeof $link.tagName === 'string' && $link.tagName === 'A') {
//     if ($link.getAttribute('target') != '_blank') {
//       loader.reInit();
//     }
//   }
// }
